import React from 'react';
import './App.css';
import logo from './logo.png'; // Загрузите логотип в папку src

function App() {
  return (
    <div className="App">
      <div className="logo-container">
        <img src={logo} alt="Бизнес Нейросети" className="logo" />
      </div>
      <div className="text-container">
        <h1>Сайт компании находится в разработке...</h1>
        <p className="info-text">Мы занимаемся комплексной разработкой ML- и ИИ-продуктов для автоматизации и оптимизации бизнес-задач, от простых чат-ботов до сложных нейросетей.</p>
        <p className="telegram-text">
          Наши кейсы в Telegram-канале <strong>«БН | Нейросети для бизнеса»</strong>
        </p>
      </div>
      <div className="button-container">
        <a href="https://t.me/BusinessNeuronet" target="_blank" rel="noopener noreferrer" className="button">
          Перейти
        </a>
      </div>
    </div>
  );
}

export default App;
